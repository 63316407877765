.search-college-results {
  position: absolute;
  width: 100%;
  background-color: #fff;
  margin-top: 0 !important;
  border: 1px solid rgba(34, 36, 38, 0.15);
  z-index: 2;
}

.search-college-results > div[role='listitem'] {
  padding: 0.7rem 1rem !important;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.search-college-result:hover,
.search-college-result.active {
  color: #ffff !important;
  background-color: #55a5a8 !important;
}

.search-college-college-link {
  display: inline-block !important;
  margin-right: 0 !important;
}
