.listContainer {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.listItem a {
  text-transform: uppercase;
  margin: 0 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.listContainer a:hover {
  color: #d20061 !important;
}

.searchHeader {
  font-weight: 200 !important;
  padding: 0 0.5em !important;
  color: #404040 !important;
}
