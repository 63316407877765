.school-core-lookup-results {
  margin: 1em 0;
  width: 90%;
  background-color: white;
}

.school-core-lookup-no-results {
  margin: 1em 0;
  width: 30%;
  background-color: pink;
}

.school-core-instructions {
  font-size: 2em;
  margin: 1em 0;
}

.school-core-form-inputs {
  width: 30%;
}

.school-core-input-field {
  margin: 0.5em 0 !important;
}

.wrapper {
  margin: 3em 2em;
}
