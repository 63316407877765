.back-button {
  margin-bottom: 0.938em;
}

.toggleBold {
  font-weight: 600;
}

.toggleNavianceHeader {
  border-bottom-width: 0 !important;
}

.banner-link {
  font-weight: bold;
  color: black;
}

.nav-denav-wrapper {
  margin-bottom: 1rem;
}

.error {
  font-weight: 700;
  color: red;
}
