.role-checkbox {
  cursor: pointer;
  width: 17px;
  height: 21px;
  vertical-align: middle;
}

.role-label {
  padding-left: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.role-fieldset {
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.role-legend {
  color: black !important;
  font-size: 0.92857143em !important;
}

.checkbox {
  display: block !important;
  padding: 5px;
}
