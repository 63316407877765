.input-range-margin {
  margin: 15px 0;
  padding: 8px 8px 15px;
}

.noPadding,
.searchBoxSegment {
  padding: 0 !important;
}

.advancedSearchContainer {
  color: #b2b3b7;
  font-weight: 300;
  padding: 0 !important;
}

.advancedResultsContainer {
  padding: 0 2em 2em !important;
}

.typeRadio {
  margin-left: 14px !important;
}

.typeCheckbox {
  margin-right: 14px !important;
  color: #b2b3b7 !important;
}

.fieldSet {
  border: 0 !important;
}

.searchBoxContainer {
  width: 100%;
}

.keywordContainer {
  padding: 8px !important;
}

.institutionContainer {
  margin-top: 10px;
  padding: 8px 8px 0 !important;
}

.institutionType {
  padding: 8px 8px 0 !important;
}

.keywordContainer > div > label,
.hsContainer > label,
.heContainer > label,
.institutionContainer > div > label,
.dropdownContainer {
  margin: 10px;
}

.lastFieldInput {
  display: block;
  margin: 10px;
  padding-bottom: 13px;
}

.keywordInput {
  display: block;
  margin: 10px;
  padding-bottom: 5px;
}

.keywordInput input {
  color: #828385 !important;
  font-weight: 300;
}

.dropdownInput {
  padding-bottom: 10px;
}

.dropdownLabel {
  margin: 10px 0 !important;
}

.institutionLabel {
  margin: 0 0 10px !important;
}

.hsContainer,
.heContainer {
  padding: 0 8px 8px !important;
}

.categoryTitle {
  font-size: 0.9em !important;
  color: #828385 !important;
  background-color: #e8e8e8;
  padding: 2px 14px 2px 18px !important;
  text-transform: uppercase;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.addPadding {
  padding: 14 !important;
}

.itemDescription {
  color: #b2b3b7 !important;
  margin-top: 0.15em !important;
  margin-bottom: 0.15em;
  font-weight: 200;
}

.itemHeader {
  color: #595959 !important;
  font-weight: normal !important;
}

.itemContainer {
  margin-top: 2em !important;
}

.joinButton {
  margin-top: 0.3em !important;
}
