.noResultsContainer,
.itemContainer {
  margin-top: 2em !important;
}

.noResultsDescription {
  color: #b2b3b7 !important;
  margin-top: 0.15em !important;
  margin-bottom: 0.15em;
  font-weight: 200;
}

.noResultsHeader {
  color: #595959 !important;
  font-weight: normal !important;
}

.itemHeader {
  margin-top: 0.2em !important;
  color: #595959 !important;
  font-weight: normal !important;
}

.itemContent {
  padding-left: 0 !important;
}

.itemDescription {
  color: #b2b3b7 !important;
  margin-top: 0 !important;
  margin-bottom: 0.2em;
  font-weight: 200;
}

.viewButton {
  margin-top: 0 !important;
  margin-bottom: 0.2em !important;
}
