.avatar {
  border-radius: 500rem !important;
  font-size: 1em !important;
  float: left !important;
  height: 2em !important;
  margin-top: 0.25em !important;
  margin-right: 0.65em !important;
  width: 2em !important;
}

.largeAvatar {
  composes: avatar;
  height: 4em !important;
  margin-top: 0 !important;
  margin-right: 15px !important;
  width: 4em !important;
}

i.largeAvatar {
  font-size: 2em !important;
}
