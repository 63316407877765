.itemDescription {
  color: #b2b3b7 !important;
  margin-top: 0.15em !important;
  margin-bottom: 0.15em;
  font-weight: 200;
}

.itemHeader {
  color: #595959 !important;
  font-weight: normal !important;
}

.itemContainer {
  margin-top: 2em !important;
}

.joinButton {
  margin-top: 0.3em !important;
}
