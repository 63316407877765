:global(.subscriptions-table.ui.table thead th) {
  color: #7f8fa4 !important;
  font-weight: normal !important;
  line-height: 19px;
  background-color: transparent !important;
}

:global(.subscriptions-table.ui.table tbody th) {
  color: #354052 !important;
}
