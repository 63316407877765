.back-button {
  margin-bottom: 0.938em;
}

.itemDescription {
  color: #b2b3b7 !important;
  margin-top: 0.15em !important;
  margin-bottom: 0.15em;
  font-weight: 200;
}

.itemHeader {
  color: #595959 !important;
  font-weight: normal !important;
}

.itemContainer {
  padding: 0 !important;
  margin-top: 2em !important;
}

.itemContent {
  padding-left: 0 !important;
}

.horizontalScroll {
  overflow: auto;
  white-space: nowrap;
}
