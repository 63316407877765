.dateInput {
  width: 145px;
}

.header {
  margin-top: 0 !important;
}

.timeframeSwitcher {
  width: 125px;
}

.dateInput::-webkit-inner-spin-button,
.dateInput::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
