.loader {
  margin-right: 10px !important;
}

.tableRow {
  line-height: 3.5em;
}

.noWrap {
  white-space: nowrap;
}

.buttons-div {
  float: right;
}
