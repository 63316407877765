.container {
  position: relative;
}

.container > textarea {
  width: 100%;
}

.counter {
  text-align: right;
  color: #4a4a4a;
  font-size: 0.8rem;
}
