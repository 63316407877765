.add-button {
  box-shadow: 0 0 0 1px #247879 inset !important;
  color: #247879 !important;
  font-weight: bold !important;
  margin-right: 2em;
}

.no-notifications-message {
  color: #616365;
  padding-top: 4rem;
  text-align: center;
  padding-bottom: 4rem;
  padding-left: 2.5em;
}

.show-more-container {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.show-more-button {
  background-color: #247879 !important;
  color: #fff !important;
  font-weight: bold !important;
}

.product-announcements {
  margin: 0 0 1em 1em !important;
}

.announcement-details {
  width: 100% !important;
}
