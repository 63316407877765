.announcementGrid {
  margin: 0 !important;
  padding: 1em !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.grey {
  color: #616365;
}

.teal-font {
  color: #218a8d !important;
}

.announcementDetails {
  font-size: 14px;
}

.announcementOthers {
  composes: grey;
}

.announcementStatus {
  composes: grey;
  text-align: right !important;
}

.announcementVisibility {
  composes: grey;
  font-weight: 600;
  font-style: normal;
}

.announcementRow {
  padding-bottom: 0 !important;
}
