.searchFieldLabel {
  display: block;
  margin: 10px;
}

.searchFieldInput {
  display: block;
  margin: 10px;
  padding-bottom: 10px;
}

.searchFieldInput input {
  color: #828385 !important;
  font-weight: 300;
}
