.globe {
  color: #bcbec0;
  font-size: 2.9em !important;
  height: 0.9em !important;
  margin-top: -3px !important;
}

.activeGlobe {
  composes: globe;
  color: #37a2a2;
}

.notificationContent {
  margin: 0.2em 0 0 0.3em !important;
}

.notificationSummary {
  max-width: 300px;
  white-space: normal;
}

.alertBubble {
  position: relative;
  margin: 0 0 -18px -24px !important;
}

.alertBubbleText {
  color: white;
  font-size: 9pt;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  position: absolute;
  left: 5px;
  top: 5px;
  text-align: center;
  width: 15px;
}

:global #notifications > .menu {
  right: -60px !important;
}
