.caption {
  display: none;
}

.actionDropdown {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
