:root {
  --gray: #616365;
  --light-gray: #b0b1b2;
  --active: #d20061;
  --light-active: #f5eaef;
}

:global .ui.fluid.search > .input {
  display: flex;
}

:global .ui.labeled.search input {
  padding-left: 3.67142857em !important;
}

:global .ui.top.right.dropdown > .menu {
  top: 100%;
  bottom: auto;
  right: 0;
  left: auto;
  margin: 1em 0 0;
}

:global .ui.visible.left.menu.sidebar ~ .fixed,
:global .ui.visible.left.menu.sidebar ~ .pusher {
  -webkit-transform: translate3d(15rem, 0, 0);
  transform: translate3d(15rem, 0, 0);
}

:global input[type='date'].no-clear::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}

:global .ui.accordion .title .chevron.icon,
:global .ui.accordion .accordion .title .chevron.icon {
  color: black !important;
  display: inline-block;
  float: right;
  opacity: 1;
  width: 1.25em;
  height: 1em;
  font-size: 1em;
  -webkit-transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
  transition: opacity 0.1s ease, -webkit-transform 0.1s ease;
  transition: transform 0.1s ease, opacity 0.1s ease;
  transition: transform 0.1s ease, opacity 0.1s ease, -webkit-transform 0.1s ease;
  vertical-align: baseline;
  -webkit-transform: none;
  transform: none;
}

:global .ui.accordion.menu .item .title > .chevron.icon {
  float: right;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

:global .ui.accordion .active.title .chevron.icon,
:global .ui.accordion .accordion .active.title .chevron.icon {
  -webkit-transform: translateY(4px) rotate(-90deg);
  transform: translateY(4px) rotate(-90deg);
}

:global .ui.accordion.menu .item .active.title > .chevron.icon {
  -webkit-transform: translateY(4px) rotate(-90deg);
  transform: translateY(4px) rotate(-90deg);
}

:global .ui.link.list .active.item {
  border-bottom: 3px solid #d20061;
}

:global .ui.link.list .active.item a:not(.ui) {
  color: #d20061 !important;
}

/* MENUS */

/* vertical */
:global ul.ui.menu.third.vertical {
  width: auto;
  margin: 0;
  padding-left: 0;

  & > li.item {
    padding: 0;

    & .menu-link {
      color: var(--gray);
      display: block;
      font-size: 1.1rem;
      padding: 1rem 2rem;
      cursor: pointer;

      &.active,
      &:hover {
        color: var(--gray);
        background: var(--light-active);
      }

      &.active::before {
        content: '';
        border-left: 6px solid var(--active);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}
