.searchBarContainer {
  position: relative;
}

.searchDropdown {
  left: 13px;
  position: absolute !important;
  top: 10px;
  z-index: 1;
}

.item {
  text-align: left;
}

.more-link {
  font-size: 0.9em;
}

:global #global-search-box-results .category .result {
  min-height: 3em;
  padding: 0.45714286em 0.8em;
}

:global #global-search-box-results > .category > .name {
  padding-bottom: 0 !important;
  padding-top: 0.1em !important;
}

:global #global-search-box-results > .category .result .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:global #global-search-box-results > .category .result .description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.advancedSearchButton {
  box-shadow: none !important;
  font-size: 0.7em !important;
  position: absolute;
  right: 0;
}

.search-by-scid-btn {
  float: right;
  position: relative;
  top: -20px;
  border: 1px solid;
  border-radius: 5px;
  background: transparent;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  font-style: italic;
}

.search-by-scid-btn:hover {
  background: #bcbec0;
  color: #fff;
}
