@media only screen and (max-width: 767px) {
  :global .hidden-mobile {
    display: none !important;
  }

  :global .visible-mobile-block {
    display: block !important;
  }

  :global .visible-mobile-inline {
    display: inline !important;
  }

  :global .visible-mobile-flex {
    display: flex !important;
  }

  :global .visible-mobile-inline-block {
    display: inline-block !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  :global .hidden-tablet {
    display: none !important;
  }

  :global .visible-tablet-block {
    display: block !important;
  }

  :global .visible-tablet-inline {
    display: inline !important;
  }

  :global .visible-tablet-flex {
    display: flex !important;
  }

  :global .visible-tablet-inline-block {
    display: inline-block !important;
  }
}

@media only screen and (min-width: 992px) {
  :global .hidden-computer {
    display: none !important;
  }

  :global .visible-computer-block {
    display: block !important;
  }

  :global .visible-computer-inline {
    display: inline !important;
  }

  :global .visible-computer-flex {
    display: flex !important;
  }

  :global .visible-computer-inline-block {
    display: inline-block !important;
  }
}
