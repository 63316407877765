.sidebar {
  background: #0e2235 !important;
  border: 0 !important;
  display: flex;
  flex-direction: column;
  flex: 0 0 15rem;
  overflow-x: hidden;
  overflow-y: auto;
  width: 15rem !important;
}

.spacer {
  flex: 1 0 auto;
}
