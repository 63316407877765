.menu {
  justify-content: center;
  flex: 0 0 auto;
  position: relative;
}

.menu::before {
  background: rgba(255, 255, 255, 0.08);
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.menu-item {
  padding: 0 !important;
}

.menu-link {
  display: block;
  padding: 0.92857143em 1.14285714em;
  white-space: nowrap;
}
