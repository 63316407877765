.dateInput {
  width: 145px;
}

.header {
  margin-top: 0 !important;
}

.timeframeSwitcher {
  width: 125px;
}
