:root {
  --border: rgba(34, 36, 38, 0.1);
  --green: #1e787a;
}

:global .DayPicker-NavButton {
  height: auto !important;
  right: auto !important;
  top: 0.45em !important;
  width: auto !important;
}

:global .DayPicker-NavButton--prev {
  background-image: none !important;
  left: 0.5em !important;
}

:global .DayPicker-NavButton--prev::before {
  content: '\f104';
  color: var(--green);
}

:global .DayPicker-NavButton--next {
  background-image: none !important;
  right: 0.5em !important;
}

:global .DayPicker-NavButton--next::before {
  content: '\f105';
  color: var(--green);
}

:global .DayPicker-Caption {
  text-align: center;
}

:global .DayPicker-NavBar {
  font-family: Icons;
  font-size: 2em;
}

:global .DayPicker-Day--today {
  border-bottom: 2px #d20061 solid;
  color: #d20061 !important;
  font-weight: normal !important;
}

:global .DayPicker-Day {
  border-radius: 0 !important;
  box-shadow: inset 1px 0 var(--border), inset 0 1px var(--border);
}

:global .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #d20061 !important;
  color: white !important;
}

:global .DayPicker-Body {
  box-shadow: 1px 1px var(--border);
}

:global .DayPicker-Day--disabled {
  background-color: #f7f7f8 !important;
  color: #616365 !important;
}

:global .DayPicker-TodayButton {
  color: var(--green) !important;
  font-size: 1em !important;
}

:global .DayPicker-Footer {
  padding-left: 0.5em !important;
  padding-top: 0.9em !important;
  margin-bottom: -1em;
}
