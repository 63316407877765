.radio-status input:checked ~ label::after {
  background-color: #247879 !important;
}

.title-limit-message {
  font-size: 11px !important;
  font-weight: normal !important;
  padding-top: 5px !important;
}

.tooltip {
  line-height: 1.3em !important;
}

.dropdown-multiselect {
  font-size: 14px !important;
}

.product-title {
  font-size: 20px;
  margin-bottom: 40px;
}

.form-label {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.form-label-mandatory::after {
  content: ' *' !important;
  color: #d0021b !important;
}

.inline {
  display: inline-block;
}

.more-padding {
  padding: 10px 20px 20px 20px !important;
}

.more-padding-left {
  padding-left: 20px;
}

.more-padding-top {
  padding-top: 20px;
}

.title-info-message {
  display: block;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 14px;
}

.form-field {
  max-width: 60% !important;
}

.submit-form-field {
  text-align: right !important;
}

.form-divider {
  margin-top: 80px !important;
}

.save-btn {
  font-weight: bold !important;
}

.success-message {
  position: fixed !important;
  top: 0;
  width: 500px;
}

.error-message-icon {
  font-size: 24px !important;
  margin-right: 25px !important;
}

.error-message {
  background-color: #fdf0ef !important;
  border: 1px solid #f2938d !important;
  box-shadow: none !important;
  color: #616365 !important;
  line-height: 1.5em !important;
  margin-bottom: 20px !important;
  padding-right: 40px !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border-radius: 4px !important;
}

.error-message-header {
  color: #616365 !important;
}
