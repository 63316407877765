.menu {
  background-color: transparent !important;
  border-radius: 0 !important;
  flex: 0 0 auto;
  position: relative;
  width: 16rem !important;
}

.menu-header {
  padding: 1.5rem 1.3rem 1.1rem 0.2rem;
  text-align: center;
  filter: grayscale(100%) brightness(1000%);
}

.menu-item {
  padding: 0 !important;
}

.menu-item::before {
  background: transparent !important;
}

.menu-icon {
  width: 1.18em;
  float: right;
  margin: 0 0 0 0.5em;
}

.menu-link {
  color: #b7c0cd !important;
  display: block;
  padding: 0.62857143em 1.14285714em;
}

.menu-link.active {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

.menu-sub-header {
  color: #d7d8d8 !important;
  display: block;
  font-size: 12px;
  padding: 0.92857143em 1.4285714em 0.2em;
  text-transform: uppercase;
}

.menu-divider {
  border-bottom: #243647 1px solid;
  margin: 20px auto 0 !important;
  width: 180px;
}
