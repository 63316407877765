.pageContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.pageContent {
  flex: 1 1;
  margin-left: 2.15rem;
  background: white;
}

.container-title {
  padding-bottom: 1em;
  padding-top: 1em;
  font-weight: 400;
}
