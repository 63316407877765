
:global(.link-button.ui.basic.teal.button),
:global(.link-button.ui.basic.teal.button:hover) {
  background-color: white !important;
}

.link {
  text-transform: uppercase !important;
  font-weight: bold !important;
  margin-left: 1rem !important;
}

.he-account-links {
  align-self: center;
  margin-left: auto;
}

.he-account-filters {
  align-self: center;
  margin-left: auto;
  margin-right: 5px;
}

:global(.light-title) {
  font-weight: normal !important;
}

.title-with-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title-with-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.only-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filter-radio {
  margin-left: 20px;
}
