.loader {
  margin-right: 0.625em !important;
}

.statusDropdown {
  left: 0.813em;
  position: absolute !important;
  top: 0.625em;
  z-index: 1;
}

.tableRow {
  line-height: 3.5em;
}

.noWrap {
  white-space: nowrap;
}

.buttons-div {
  float: right;
  margin-top: 0.938em;
}

.error {
  font-weight: 700;
  color: red;
}
