.container {
  margin: -20px;
}

@media (max-width: 990px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }
}

.data-list-container {
  margin-right: 3rem;
  float: left;
}

.data-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.light-title {
  font-weight: normal !important;
}

.content-header {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.menu {
  padding-left: 0;
  max-width: 1125px;
  margin: 0 auto !important;
  border-bottom: none !important;
}

.menu-item {
  margin-right: 2rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.menu-link {
  padding: 0;
}

.menu-link > a {
  line-height: 58px;
}

.active,
.menu-link:hover,
.menu-link:active {
  color: #616365;
}

.active::after {
  content: '';
  border-bottom: 3px solid #d20061;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
}

.page-content {
  max-width: 1125px;
  margin: 0 auto;
}

.page-menu {
  left: 0;
  right: 0;
  margin-left: -30px;
  margin-right: -30px;
  background-color: white;
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);
}

@media (max-width: 990px) {
  .page-menu {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.buttonlink {
  text-transform: uppercase !important;
  font-weight: bold !important;
  margin-left: 1rem !important;
}

.camino-buttons {
  margin-top: 20px;
}

.camino-page-content {
  max-width: 600px;
}

.camino-input {
  max-width: 600px;
}
