.userDropdown {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.lineItem {
  display: list-item;
  text-align: -webkit-match-parent;
}

.avatar {
  width: 2.5em !important;
  height: 2.5em !important;
}

.add-feature-group {
  margin: 0 !important;
}

.global-features-overflow {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
}

.alertBubble {
  position: absolute;
  right: -9px;
  top: 15px;
}
