/* Global styles */

.wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.content {
  flex: 1 1 auto;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
}

@media only screen and (min-width: 992px) {
  .content {
    padding: 20px 50px;
  }
}

.header {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  padding: 0.75rem;
}

.main {
  background-color: #f7f7f7;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.footer {
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
}

.hidden {
  display: none;
}
