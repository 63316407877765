.sectionTitle {
  margin-bottom: 20px !important;
}

.cardContent {
  padding: 40px !important;
}

.listHeader {
  margin-bottom: 10px !important;
  color: #767676 !important;
  letter-spacing: 0;
  line-height: 19px;
  font-weight: normal !important;
  text-align: center;
  font-size: 15px !important;
}

.listContent {
  font-size: 1.5em;
}

.listItem {
  text-transform: capitalize;
}

.list {
  display: inline-flex !important;
  justify-content: space-between;
  text-align: center;
  width: 80%;
  padding: 2em !important;
}

.listItemHorizontal {
  display: flex !important;
  flex-direction: row;
  margin-bottom: 5px;
}

.listItemHorizontalHeader {
  margin-right: 5px !important;
}

.centerCard {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.centerCardLabel {
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.centerCardContent {
  font-size: 20px;
}

.health-report-section-container {
  margin-top: 2em !important;
}

.health-report-card-content {
  max-width: 250px;
  margin: 20px auto !important;
  text-align: center;
}

.health-report-aa-connection-title,
.health-report-count-header {
  color: #767676 !important;
  letter-spacing: 0;
  line-height: 19px;
  font-weight: normal !important;
  text-align: center;
  font-size: 15px !important;
}

.health-report-aa-connection-value,
.health-report-count {
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #252628;
}

@media screen and (min-width: 750px) {
  .health-report-aa-connection-title {
    height: 40px;
  }
}
