.iframe {
  flex: 1 1 auto;
  height: 100%;
}

.wrapper {
  display: flex;
  height: calc(100% + 40px);
  margin: -20px;
}

@media only screen and (min-width: 992px) {
  .wrapper {
    margin: -20px -50px;
  }
}
