.toast {
  position: fixed;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;
  z-index: 1001;
}

.exit {
  opacity: 1;
  transition: opacity 0.7s ease-in;
}

.exitActive {
  opacity: 0.01;
}

.appear {
  opacity: 0.01;
}

.appearActive {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
