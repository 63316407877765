.subscription-modal {
  border-radius: 0 !important;
}

.subscription-modal > h1 {
  border-bottom: none !important;
  font-weight: normal !important;
  font-size: 1.8rem !important;
}

.subscription-modal-close {
  top: 10px !important;
  right: 10px !important;
  border: 1px solid #000;
  border-radius: 50%;
  padding-top: 0.45rem !important;
}

.subscription-modal h3 {
  font-weight: normal !important;
}

.subscription-types {
  margin-bottom: 50px;
}

.subscription-type {
  margin-right: 30px;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.form-label {
  color: #555 !important;
  font-weight: normal !important;
  font-size: 1.1rem !important;
}

.form-label-big {
  composes: form-label;
  font-size: 1.5rem !important;
}

.date-container {
  display: flex;
  flex-direction: column;
}

.date-button {
  width: 200px;
  text-align: left !important;
  padding: 0.75em 1em !important;
}

.date-button > i {
  float: right;
  margin-right: 0 !important;
}

.date-hyphen {
  margin: auto 0;
}

@media only screen and (max-width: 600px) {
  .date-hyphen {
    display: none;
  }
}

.select-field {
  margin: 0;
  outline: none;
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.4em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: none;
}

.select-field:hover {
  border-color: rgba(34, 36, 38, 0.35);
  box-shadow: none;
}

.select-field.active {
  border-color: #96c8da;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
}

.select-field input {
  display: inline-block !important;
  width: 5rem !important;
  border: 0 !important;
  background: none transparent !important;
}

.select-field .competitors-search-input {
  width: 40% !important;
}

.selected-pill {
  margin-bottom: 0.15em !important;
  background-color: #f1fafa !important;
  border: 1px solid #daf3f3 !important;
  font-weight: 400 !important;
  font-size: 1em !important;
}

.competitors-container {
  position: relative;
}

:global(.custom-dropdown) {
  padding: 10px 15px 5px 15px !important;
  border: 1px solid #d0d1d8 !important;
}

:global(.custom-dropdown > .ui.label) {
  margin: 0 !important;
  background-color: #f1fafa !important;
  line-height: 17px !important;
  font-size: 1rem !important;
  border: 1px solid #daf3f3 !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
  font-weight: normal !important;
  box-shadow: none !important;
}

:global(.custom-dropdown > .ui.label > .icon) {
  color: #37a2a2 !important;
}

.selection-label-icon {
  color: #37a2a2 !important;
}

:global(.custom-dropdown .menu .item) {
  line-height: 26px;
}

:global(.custom-dropdown .menu .active.item) {
  color: #55a5a8 !important;
}

:global(.custom-dropdown .menu .item:hover) {
  background-color: #55a5a8 !important;
  color: #fdfdfd !important;
}

:global(.custom-dropdown.selection.active.dropdown .menu) {
  border: 1px solid #e6eaee !important;
}

.dropdown-icon {
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 2em;
  color: #a8aab7;
  cursor: pointer;
}

:global(.custom-rounded-dropdown) {
  border-radius: 50px !important;
}

:global(.custom-rounded-dropdown.ui.active.selection.dropdown) {
  border-radius: 50px !important;
}

:global(.custom-rounded-dropdown.selection.active.dropdown .menu) {
  border: 1px solid #e6eaee !important;
}

:global(.custom-rounded-dropdown .menu .active.item) {
  color: #55a5a8 !important;
}

:global(.custom-rounded-dropdown .menu .item:hover) {
  background-color: #55a5a8 !important;
  color: #fdfdfd !important;
}

.rounded-dropdown-icon {
  float: right;
  position: absolute;
  line-height: 1em;
  color: #a8aab7;
  cursor: pointer;
  right: 10px;
}
