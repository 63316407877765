.search-row {
  display: none;
  padding-top: 0 !important;
}

.pageCaption {
  line-height: 32px;
  margin-left: 37px;
}

.pageTitle {
  color: #252628;
  font-size: 32px;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  height: 36px;
  margin-bottom: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1560px) {
  .pageTitle {
    font-size: 24px;
  }
}

.pageCategory {
  color: #252628;
  font-size: 14px;
  height: 24px;
  letter-spacing: 1.6px;
  padding-left: 2px;
  text-transform: uppercase;
}
